/* HERE LAYS MODIFIED VERSTION FOR MODASH */
export const ModashFilters = {
  influencer_followers: { min: 100000, max: 300000 },
  influencer_gender: '',
  // account_type: 'any', // seems like there is no such thing in modash
  // category: { include: [], exclude: [] }, // categories are not supported in modash
  // interests: [], // interests are not supported in modas (?)
  // influencer_relevance: [], // this might be an alternative for interests. ONLY 15 ENTRIES CAN BE SENT!
  influencer_location: [148838], // id for US
  // influencer_language: ['en'],
  influencer_age: { min: 18, max: 45 },
  // verified: 'null', // seems like there is no such thing in modash
  // influencer_hasContactDetails: [],
  // influencer_keywords: [],
  // post_price: { from: 10, to: 220 }, // seems like there is no such thing in modash
  // aqs: {
  //   from: 80,
  //   to: 100,
  // }, // seems like there is no such thing in modash
  audience_location: [
    {
      id: 148838,
      label: 'United States',
      weight: 0.5,
    },
  ],
  audience_gender: {
    id: 'MALE',
    weight: 0.5,
  },
  // ethnicity: [], // seems like there is no such thing in modash
  audience_age: [
    {
      id: '18-24',
      weight: 0.45,
    },
  ],

  // metricsBrandSaftey: false, // seems like there is no such thing in modash
  // excludeContent: {
  //   toxic: false,
  //   offensive: false,
  //   negativeSentiments: false,
  //   political: false,
  //   pranks: false,
  //   adult: false,
  //   religious: false,
  //   alcohol: false,
  //   crime: false,
  // }, // seems like there is no such thing in modash
  // influencer_engagementRate: {
  //   from: 0, // MAX IS 5!
  // },
  // comments_rate: ['any'], // no such thing in modash
  // influencer_followersGrowthRate: {
  //   interval: 'i3months',
  //   operator: 'gte',
  //   value: 3, // MAX IS 5!
  // },
  // account_mentions: {
  //   include: [],
  //   exclude: [],
  // }, // no such thing in modash
  // influencer_lastposted: null,
  similar: '',
};

/* eslint-disable import/prefer-default-export */
export const HypeAuditorFilters = {
  subscribers_count: { from: 100000, to: 300000 }, // influencer_followers
  account_gender: '', // influencer_gender
  account_type: 'any', // no such thing in modash
  category: { include: [], exclude: [] }, // no such thing in modash
  interests: [], // no such thing in modash
  account_geo: {
    country: ['us'],
  }, // influencer_location
  account_languages: ['en'], // influencer_language
  account_age: { from: 18, to: 31 }, // influencer_age
  verified: 'null', // no such thing in modash
  account_has_contacts: false, // influencer_hasContactDetails
  influencerKeyWords: [], // influencer_keywords
  post_price: { from: 10, to: 220 }, // no such thing in modash
  aqs: {
    from: 80,
    to: 100,
  }, // no such thing in modash
  audience_geo: {
    countries: [
      {
        id: 'us',
        prc: 50,
      },
    ],
  }, // audience_location
  audience_gender: {
    gender: 'any',
    prc: 45,
  }, // audience_gender DIFFERENT DATA FORMAT
  ethnicity: [], // no such thing in modash
  audience_age: {
    groups: ['18_24'],
    prc: 45,
  }, // audience_age DIFFERENT DATA FORMAT

  metricsBrandSaftey: false, // no such thing in modash
  excludeContent: {
    toxic: false,
    offensive: false,
    negativeSentiments: false,
    political: false,
    pranks: false,
    adult: false,
    religious: false,
    alcohol: false,
    crime: false,
  }, // no such thing in modash
  er: {
    from: 0,
  }, // influencer_engagementRate
  comments_rate: ['any'], // no such thing in modash, but can be calculated with modash data
  growth: {
    period: '90d',
    from: 20,
  }, // influencer_followersGrowthRate DIFFERENT DATA FORMAT
  account_mentions: {
    include: [],
    exclude: [],
  }, // no such thing in modash
  last_media_time: null, // influencer_lastposted influencer_lastposted ! Should be number biger than 30, num is representing days
  similar: '', // similar
};

import moment from 'moment';
import { CONTENT_TYPES } from './constants';
import { EDITOR_URL } from '../../../constants/internalUrls';
import { EMAIL_GENERIC_TYPE } from '../../../constants/internalConsts';

export const getGraphLabels = (limitStart, limitEnd) => {
  const labels = [];
  for (let i = limitEnd; i >= limitStart; i -= 1) {
    labels.push(
      moment()
        .subtract(i, 'days')
        .format('MM-DD')
    );
  }
  return labels;
};

export const getDefaultDate = () =>
  `${moment()
    .subtract(1, 'year')
    .format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}`;

export const updateUrlSearch = (history, location, key, value) => {
  const searchParams = new URLSearchParams(location.search);
  if (value === null || key === 'kpi') {
    // Remove filter if KPI or if value is null
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  history.push({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
};

export const updateUrlFilters = ({
  searchParams,
  history,
  location,
  urlFilters, // kpi, profile, contentType, date, page
}) => {
  Object.keys(urlFilters).forEach(key => {
    if (urlFilters[key] !== null) {
      searchParams.set(key, urlFilters[key]);
    } else {
      searchParams.delete(key);
    }
  });
  searchParams.delete('kpi'); // no kpi in url, it depends on page load
  history.push({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
};

export const getRowCellData = (columnNames, engagementData, n) => {
  const idx = columnNames[n];
  return engagementData[idx];
};

export const buildEditorArticleUrl = (contentType, row) => {
  let { integrationType = 'AR', id: postId } = row;
  const { integrationPostId, status } = row;
  let isDraft = false;
  if (
    integrationType === null ||
    integrationType === 'AR' ||
    integrationType.includes('Custom')
  ) {
    integrationType = 'AR';
  } else {
    postId = integrationPostId;
  }
  if (
    (integrationType === 'AR' && status === 'DRAFT') ||
    status === 'ARCHIVED'
  ) {
    isDraft = true;
  }

  const obj = CONTENT_TYPES.find(el => el.id === contentType);

  const { protocol, host } = window.location;
  let editorUrl = `${protocol}//${host}${EDITOR_URL.replace(
    ':contentType',
    obj.editorUrlId
  )}`
    .replace(':contentId?', postId)
    .replace(':integrationType?', integrationType);
  if (isDraft) {
    editorUrl = `${editorUrl}?drafts`;
  }

  return editorUrl;
};

export const buildEditorEmailUrl = (contentType, row) => {
  const {
    integrationType = 'HUBSPOT',
    id,
    integrationEmailId,
    isDraft,
    isArchived,
  } = row;
  const obj = CONTENT_TYPES.find(el => el.id === contentType);
  const { protocol, host } = window.location;
  const contentId =
    integrationType === EMAIL_GENERIC_TYPE ? id : integrationEmailId;
  let editorUrl = `${protocol}//${host}${EDITOR_URL.replace(
    ':contentType',
    obj.editorUrlId
  )}`
    .replace(':contentId?', contentId)
    .replace(':integrationType?', integrationType);

  if (integrationType === 'AR' && (isDraft === true || isArchived === true)) {
    editorUrl = `${editorUrl}?drafts`;
  }

  return editorUrl;
};

export function formatNumberToMil(value) {
  return value > 999999
    ? `${Math.round((value / 1000000) * 100) / 100}M`
    : `${Math.round(value / 1000)}k`;
}

export function formatNumbersWithComma(value) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumbersWithNoZero(value) {
  const stringValue = value.toString();

  if (stringValue.includes('.')) {
    const [integerPart, decimalPart] = stringValue.split('.');
    const formattedDecimalPart = decimalPart.replace(/0+$/, '');
    const formattedValue = formattedDecimalPart.length
      ? `${integerPart}.${formattedDecimalPart}`
      : integerPart;

    return formattedValue;
  }

  return stringValue;
}

export function formatNumber(number) {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(number);
}

export function formatNumberWithPercent(number) {
  return `${Math.round(number * 100) / 100}%`;
}

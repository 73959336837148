import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../_helpers';
import configData from '../../../../config/loader';
import logo from './logo.svg';
import oprgLogo from './oprg.png';
import { Button } from 'antd';
import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavigationBar } from '../../Layout.slice';

export function Brand() {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const { isNavMinimized } = useSelector(state => state.Layout);

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img alt={configData.companyName} src={oprgLogo} />
        </Link>
        {/* end::Logo */}

        {/* {layoutProps.asideSelfMinimizeToggle && (
          <>
            <Button onClick={() => {
              dispatch(toggleNavigationBar())
            }}
              style={{
                transform: isNavMinimized ? 'scaleX(-1)' : 'none'
              }}
              className="brand-toggle" id="kt_aside_toggle">
              <ArrowLeftIcon />
            </Button>
          </>
        )} */}
      </div>
      {/* end::Brand */}
    </>
  );
}

import prod from './prod.json';
import localhost from './localhost.json';
import dev from './dev.json';
import rad from './rad.json';

let configData;
switch (window.location.host) {
  case 'localhost:3000':
    configData = rad;
    // configData = localhost;
    // configData = prod;
    break;
  case 'dev.atomicreach.com':
  case 'dev-newui.atomicreach.com':
  case 'dev-newui.radintel.ai':
    configData = rad;
    break;
  case 'rad.atomicreach.com':
  case 'analyze.radintelligence.app':
  case 'dev.radintel.ai':
  case 'app.radintel.ai':
  case 'new.radintel.ai':
    configData = rad;
    break;
  case 'oprg.radintel.ai':
    configData = rad;
    break;
  default:
    configData = rad;
    break;
}

export default configData;
